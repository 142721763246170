const messages = {
    fr: {
        home: 'Accueil',
        about: 'À propos',
        service: 'Services',
        portfolio: 'PortFolio',
        price: 'Prix',
        career: 'Carrière',
        contact: 'Contact',
        contactText1: 'Contactez',
        welcome: 'Bienvenue aux',
        discover: 'DECOUVRIR',
        lesSial: 'SERVICES INFORMATIQUES',
        secMission: 'Notre mission',
        address: 'Addresse',
        tel: 'Téléphone',
        privacyPolicy: 'Tous droits réservés',
        secMissionText1: "À l'air de la modernisation et même du télétravail qui s'impose de plus en plus, il est plus que jamais utile et nécessaire pour tout professionnel de bien s'outiller et de trouver des solutions technologiques novatrices afin de faciliter son travail et celui de ses employés dans l'optique de mieux répondre aux besoins de sa clientèle.",
        secMissionText2: 'Ainsi donc offrir un service TI personnalisé, sur mesure, de qualité et à coût raisonnable aux travailleurs autonomes (électricien, mécanicien, entrepreneur, paysagiste, plombier, etc), aux startups et aux PME pour les accompagner dans ce défi est notre priorité.',
        secMissionText3: "Nous nous engageons à Être à votre écoute, vous accompagner et vous donner des outils simples qui vous aiderons dans vos activités quotidiennes et qui vous ferons gagner du temps.",
        secService: 'Nos services',
        secServiceText1: "Nous sommes une équipe d'experts TI, spécialisée en intelligence d'affaire, en développement d'applications et en cybersécurité et ayant une grande expérience dans le domaine qui avons décidé de nous occuper personnellement de vous car pour nous, tout professionnel a besoin d'un bon accompagnement TI",
        secServiceOffice: 'Gestion de la Plateforme Office 365',
        secServiceOfficeText: "Analyse des besoins, proposition d'une solution office 365 adaptée pour votre besoin, déploiement de la solution, définition de l'architecture collaborative, Mise en place de la gouvernance, administration, formations des utilisateurs interne...",
        secServiceDevApp: 'Développement de solutions applicative web et mobile avec la Power Platform',
        secServiceDevAppText: 'Selon vos besoins nous développons rapidement des solutions applicatives simples, personnalisées et intégrées à votre environnement. Nous faisons un développement low code/No code. Ainsi donc en quelques heures nous pouvons vous offrir une solution répondant en tout point à votre besoin.',
        secServiceCyberSecu: 'Cyber sécurité',
        secServiceCyberSecuText: 'Nos experts TI en sécurité informatique sont là pour vous. Nous évaluons votre environnement, faisons une analyse de risque et vous aidons à établir une gouvernance pour mieux vous prémunir des risques de sécurité.',
        secServiceSupport: 'Support Technique',
        secServiceSupportText: 'Nous offrons un support technique des applications que nous développons. Nous pouvons également maintenir et faire évoluer les applications déjà existantes développées avec les technologies que nous utilisons.',
        secContactUs: 'Nous contacter',
        secContactUsFormHeader: 'Entrez vos informations',
        secContactUsFormName: 'Votre nom',
        secContactUsFormEmail: 'Votre email',
        secContactUsFormMessage: 'Votre message',
        secContactUsFormLastName: 'Votre Prenom',
        secContactUsFormCV: 'Votre CV',
        secContactUsFormPost: 'Poste sollicité',
        secContactUsFormSubmit: 'Envoyez',
        secPrice: 'Nos prix',
        secPriceText1: "Jusqu'à 3h de consultation gratuite",
        secPriceText2: 'Soumission et Estimation gratuite',
        secPriceText3: 'Support applicatif garantie et offert',
        secPriceText4: 'Code source offert',
        secPriceText5: 'Prix et paiement flexible',
        careerText1: "Agent marketing",
        careerText2: "Web designer",
        careerText3: "Agent réseaux",
        careerApply: "POSTULEZ",
        achievement: "Nos ouvrages",
        technology: "Nos technologies",
        technologyText1: "Microsoft PowerApps",
        technologyText2: "Microsoft PowerAutomate",
        technologyText3: "Microsoft PowerBI",
        technologyText4: "SharePOint Online",
        technologyText5: "MIcrosoft Forms",
        technologyText6: "SQL",
        technologyText7: "PowerShell",
        technologyText8: "Office 365",
        technologyText9: "Saleforce",
        homeText1: "Un accompagnement TI personnalisé pour vous",
        homeText2: "J'aimerais avoir un site web pour présenter mes services ",
        homeText3: "Comment fonctionne la suite Microsoft 365 (Teams, SharePoint, OneDrive, la PowerPlatForm, etc)? Qu'est-ce qu'elle peut m'apporter de plus dans mon travail? Est-ce que j'utilise les outils de la bonne façon ?",
        homeText4: "J'ai besoin d'un service de messagerie électronique professionnel et des outils me permettant de communiquer avec mes clients ou mes employés.",
        homeText5: "Comment organiser mes fichiers? Où les sauvegarder? Comment m'assurer de ne pas les perdre?",
        homeText6: "Comment organiser, structurer et stocker mes données ? Ai-je besoin d'une base de données?",
        homeText7: "J'aimerais analyser mes données et disposer des rapports pertinents qui m'aideront à prendre de bonne décision.",
        homeText8: "Mon infrastructure est-elle sécuritaire?",
        homeText9: "etc",
        homeText10: "Nombreux sont vos besoins auxquels nous pouvons répondre. Venez nous découvrir et regardons ensemble la meilleure solution pour vous.",
        homeText11: "Venez nous découvrir et regardons ensemble la meilleure solution pour vous.",
        portFolio1: "Outil de facturation",
        portFolio2: "Plateforme d'envoie de SMS",
        secTeam: "Notre équipe",
        noPosition: "Nous n'avons pas de poste disponible pour l'instant, merci pour votre intérêt",
        successEmail: 'Message envoyé avec success',
        errorEmail: 'Erreur de reseau',
    },
    en: {
        home: 'Home',
        about: 'About',
        service: 'Services',
        portfolio: 'PortFolio',
        price: 'Prices',
        career: 'Career',
        contact: 'Contact',
        welcome: 'Welcome to the',
        discover: 'DISCOVER',
        lesSial: 'IT SERVICES',
        secMission: 'Our mission',
        address: 'Address',
        tel: 'Telephone',
        privacyPolicy: 'All rights reserved',
        secMissionText1: 'In this era of modernization and even teleworking, it is more than ever useful and necessary for any professional to be well equipped and to find innovative technological solutions in order to facilitate his work and that of his employees in order to better respond to the needs of his customers',
        secMissionText2: 'Thus, offering a personalized, customized, quality and affordable IT service to self-employed workers (electricians, mechanics, contractors, landscapers, plumbers, etc.), startups and small and medium-sized businesses to support them in this challenge is our priority',
        secMissionText3: "We are committed to listening to you, supporting you and giving you simple tools that will help you in your daily activities and save you time.",
        secService: 'Our services',
        secServiceText1: 'We are a team of IT experts, specialised in business intelligence, application development and cybersecurity and with a great experience in the field, who have decided to take care of you personally because for us, every professional needs a good IT support',
        secServiceOffice: 'Management of the Office 365 platform',
        secServiceOfficeText: 'Needs analysis, proposal of an office 365 solution adapted to your needs, deployment of the solution, definition of the collaborative architecture, implementation of governance, administration, internal user training...',
        secServiceDevApp: 'Development of web and mobile application solutions with the Power Platform',
        secServiceDevAppText: 'According to your needs we quickly develop simple, customised and integrated application solutions for your environment. We do low code/No code development. So within a few hours we can offer you a solution that meets your needs',
        secServiceCyberSecu: 'Cyber security',
        secServiceCyberSecuText: 'Our IT security experts are here for you. We assess your environment, perform a risk analysis and help you establish governance to better protect you from security risks',
        secServiceSupport: 'Technical Support',
        secServiceSupportText: 'We provide technical support for the applications we develop. We can also maintain and upgrade existing applications developed with the technologies we use',
        secContactUs: 'Contact us',
        secContactUsFormHeader: 'Enter your information',
        secContactUsFormName: 'Your name',
        secContactUsFormEmail: 'Your email',
        secContactUsFormMessage: 'Your message',
        secContactUsFormLastName: 'Your Lastname',
        secContactUsFormCV: 'Choose your CV',
        secContactUsFormPost: 'Post applied for',
        secContactUsFormSubmit: 'Send',
        secPrice: 'Our prices',
        secPriceText1: 'Up to 3 hours free consultation',
        secPriceText2: 'Free quote and estimate',
        secPriceText3: 'Guaranteed and free application support',
        secPriceText4: 'Free source code',
        secPriceText5: 'Flexible pricing and payment',
        contactText1: 'Contact',
        careerText1: "Marketing agent",
        careerText2: "Web designer",
        careerText3: "Network agent",
        careerApply: "APPLY",
        achievement: "Our achievements",
        technology: "Our technologies",
        technologyText1: "Microsoft PowerApps",
        technologyText2: "Microsoft PowerAutomate",
        technologyText3: "Microsoft PowerBI",
        technologyText4: "SharePOint Online",
        technologyText5: "Microsoft Forms",
        technologyText6: "SQL",
        technologyText7: "PowerShell",
        technologyText8: "Office 365",
        technologyText9: "Saleforce",
        homeText1: "Personalized IT services for you",
        homeText2: "I would like to have a website to present my services",
        homeText3: "How does the Microsoft 365 suite (Teams, SharePoint, OneDrive, PowerPlatForm, etc.) work? What can it do for me in my work? Am I using the tools in the right way?",
        homeText4: "I need a professional email service and tools to communicate with my clients or employees.",
        homeText5: "How do I organise my files? Where do I save them? How do I make sure I don't lose them?",
        homeText6: "How do I organise, structure and store my data? Do I need a database?",
        homeText7: "I would like to analyse my data and have relevant reports to help me make good decisions",
        homeText8: "Is my infrastructure secure?",
        homeText9: "etc",
        homeText10: "There are many needs that we can meet. Come and see us and let's look at the best solution for you",
        homeText11: "Come and see us and let's look at the best solution for you.",
        portFolio1: "Billing tool",
        portFolio2: "SMS sending platform",
        secTeam: "Our Team",
        noPosition: "No position available at the moment",
        successEmail: 'Message sent successfully',
        errorEmail: 'Network Error',
    }
};

export { messages }