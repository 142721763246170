<template>
    <div class="firstPage">
        <Header currentTab="contact"/>        
        
        <div class="contact" id="contact">
            <h2 class="reveal-1">{{$t('secContactUs')}}</h2>
            <div class="cards reveal-2">
               <ContactUs/>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from '../components/layouts/Header.vue';
import Footer from '../components/layouts/Footer.vue';
import ContactUs from '../components/forms/ContactUs.vue';

export default {
   name: "Contact",
   metaInfo: {
    title: `LesSial SERVICES INFORMATIQUES AL, Contactez nous`,
    htmlAttrs: {lang: 'fr'},
    meta: [
      { name: 'description', content: "LesSial SERVICES INFORMATIQUES AL, Contactez nous, envoyez nous un message, notre équipe" },
      { name: 'robots', content: "LesSial, powerapps, powerautomate, powerplatform, office365, sharepoint, cybersecurite, saleforce, développement, application, informatique, Service informatique" },
      { name: 'googlebot', content: "LesSial, powerapps, powerautomate, powerplatform, office365, sharepoint, cybersecurite, saleforce, développement, application, informatique, Service informatique" },
    ]
   },
   components: {Header, Footer, ContactUs},

   data(){
       return {
         
       }
   },

    props: {
        isReload: Boolean,
    },

    methods: {        
        scriptApp(){
            let body = document.querySelector(".firstPage");
            let burger = document.querySelector(".navigation-burger");
            let navLinks = document.querySelector(".navigation-links");
            let nav = document.querySelector(".navigation");
            burger.addEventListener("click", () => {
            burger.classList.toggle("active");
            navLinks.classList.toggle("active");
            body.classList.toggle("active");
            });

            var lastScrollTop = 0;

            window.addEventListener(
            "scroll",
            function () {
                if (!(burger.classList.contains("active"))) {
                var st = window.pageYOffset || document.documentElement.scrollTop;
                if (st > lastScrollTop) {
                    nav.classList.add("fixed");
                }
                if (st < lastScrollTop) {
                    nav.classList.remove("fixed");
                }
                if (window.scrollY == 0) {
                    nav.classList.remove("fixed");
                }
                lastScrollTop = st;
                }
            },
            false
            );
        },

        scriptAnim(){
            const ratio = .1
            var options = {
            root: null,
            rootMargin: '0px',
            threshold: ratio
            }

            const handleIntersect = function (entries, observer) {
                entries.forEach(function (entry) { 
                    if (entry.intersectionRatio > ratio) {
                    entry.target.classList.add('reveal-visible')
                    observer.unobserve(entry.target) 
                    } 
                })
            }

            var observer = new IntersectionObserver(handleIntersect, options);
            document.querySelectorAll('[class*="reveal-"]').forEach(element => {
                observer.observe(element)
            });
        },
    },

    mounted: function(){
        this.scriptApp();
        this.scriptAnim();
    }

}
</script>

<style scoped>

</style>
