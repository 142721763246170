import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home.vue';
import About from '../pages/About.vue';
import Service from '../pages/Service.vue';
import PortFolio from '../pages/PortFolio.vue';
import Price from '../pages/Price.vue';
import Career from '../pages/Career.vue';
import Contact from '../pages/Contact.vue';
Vue.use(VueRouter)

// const lang = sessionStorage.getItem('lesSial-lang');
const routes = [
    {
        path: `/`,
        name: 'Home',
        component: Home,
        props: true,
    },
    {
        path: `/*`,
        name: 'Home',
        component: Home,
    },
    {
        path: `/About`,
        name: 'About',
        component: About,
        props: true,
    },
    {
        path: `/Service`,
        name: 'Service',
        component: Service,
        props: true,
    },
    {
        path: `/PortFolio`,
        name: 'PortFolio',
        component: PortFolio,
        props: true,
    },
    {
        path: `/Price`,
        name: 'Price',
        component: Price,
        props: true,
    },
    {
        path: `/Career`,
        name: 'Career',
        component: Career,
        props: true,
    },
    {
        path: `/Contact`,
        name: 'Contact',
        component: Contact,
        props: true,
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
export default router