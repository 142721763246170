<template>
    <div class="cards">
        <form class="contact-card" ref="form" @submit.prevent="sendEmail">
            <h3>{{$t("secContactUsFormHeader")}}</h3>
            <div class="inputBox">
                <input type="text" name="user_name" :placeholder="$t('secContactUsFormName')" class="formInputs" required>
            </div>
            <div class="inputBox">
                <input type="email" name="user_email" :placeholder="$t('secContactUsFormEmail')" class="formInputs" required>
            </div>
            <div class="inputBox">
                <textarea name="message" :placeholder="$t('secContactUsFormMessage')" class="formInputs" required></textarea>
            </div>
            <div class="inputBox">
                <input type="submit" :value="$t('secContactUsFormSubmit')" :disabled="isSending">
            </div>
            <div class="lds-spinner" v-if="isSending"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </form>
        <!-- TODO Add spinner when sending message -->
    </div>
</template>

<script>
import * as mail from "../../utils/mails.js";
// const email = require('emailjs');

export default {
   name: "ContactUs",
   components: {},

   data(){
       return {
         isSending: false,
       }
   },


    methods: {
        async sendEmail() {
            this.isSending = true;
            try{
                const form = this.$refs.form;
                const res = await mail.sendEmail(form);
                 this.showToast(this.$t('successEmail'), 'success');
                this.isSending = false;
                document.getElementsByClassName('formInputs').forEach(elt => {
                    elt.value = '';
                });
                 console.log('>>>>>>>>>>> Success: ', JSON.stringify(res));
            }catch(e){
                 this.showToast(this.$t('errorEmail'), 'error');
                this.isSending = false;
                console.log('>>>>>>>>>>> error: ', JSON.stringify(e));
            }
        },

        async sendEmailNodemailler(){
            console.log('>>>>> sending!')
            this.isSending = true;
            try{
                const form = this.$refs.form;
                const res = await mail.sendWithNodeMailler(form[0].value, form[1].value, form[2].value, form);
                this.isSending = false;
                if(res.data.success){
                    this.showToast(this.$t('successEmail'), 'success');
                    document.getElementsByClassName('formInputs').forEach(elt => {
                        elt.value = '';
                    });
                }else{
                    this.showToast(this.$t('errorEmail'), 'error');
                    console.log('>>>>>>>> error send email: ', res.data.error);
                }
            }catch(e){
                this.showToast(this.$t('errorEmail'), 'error');
                this.isSending = false;
                console.log('>>>>>>>>>>> error: ', JSON.stringify(e));
            }
        },
        showToast(message, type) {
            this.$toasted.show(message, {
                type: type,
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 5000
            })
        },
    },

    mounted: function(){
    }

}
</script>

<style scoped>
    .lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin-left: 40vh;
    margin-right: 40vh;
    }
    .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
    }
    .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #055592;
    }
    .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
    }
    .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
    }
    .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
    }
    .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
    }
    .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
    }
    .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
    }
    .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
    }
    .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
    }
    .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
    }
    .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
    }
    .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
    }
    .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    }
    @keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
    }
</style>
