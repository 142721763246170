"use strict";
import emailjs from '@emailjs/browser';
import axios from "axios";
// const nodemailer = require("nodemailer");

const BASE_URL = 'https://lessial.ca';
//const BASE_URL = 'http://localhost:3080';

async function sendEmail(form) {
    const emailCred = await axios.get(`${BASE_URL}/emailjsCred`);

    return new Promise((resolve, reject) => {
        console.log('>>>>>> emailCred: ', emailCred.data.serviceID, emailCred.data.templateID, emailCred.data.userID);
        emailjs.sendForm(emailCred.data.serviceID, emailCred.data.templateID, form, emailCred.data.userID)
            .then(() => {
                resolve(`${sessionStorage.getItem('lesSial-lang')=='fr' ? 'Message envoyé avec succès' : 'Message sent successfuly'}`)
            }, (error) => {
                reject(error.text);
            });
    })
}

async function sendWithNodeMailler(senderName, senderEmail, senderMessage, form){
    return await axios.get(`${BASE_URL}/sendEmail`, {senderName: senderName, senderEmail: senderEmail, senderMessage: senderMessage, form: form});
}

export { sendEmail, sendWithNodeMailler }