<template>
    <div class="firstPage">
        <Header currentTab="about"/>
        <div class="mission reveal" id="team">
            <h2 class="reveal-1">{{$t("secTeam")}}</h2>
            <div class="cards">
                <div class="mission-card reveal">
                    <div class="mission-card-text reveal-1">
                        <p>{{$t("secServiceText1")}}</p>
                        <router-link :to="{name: 'Contact'}"><button>{{$t("contactText1")}}</button></router-link>
                    </div>
                    <img class="reveal-2" src="../assets/images/notreEquipe.jpeg" alt="LesSial team: équipe d'experts TI">
                </div>                
            </div>
        </div>
             
        <div class="mission" id="mission">
            <h2 class="reveal-1">{{$t("secMission")}}</h2>
            <div class="cards">
                <div class="mission-card reveal">
                    <img class="reveal-1" src="../assets/images/7.jpg" alt="LesSial mission: solution technologique">
                    <div class="mission-card-text reveal-2">
                        <p>{{$t("secMissionText1")}}</p>
                        <router-link :to="{name: 'Contact'}"><button>{{$t("contactText1")}}</button></router-link>
                    </div>
                </div>
                <div class="mission-card reveal">
                    <div class="mission-card-text reveal-3">
                        <p>{{$t("secMissionText2")}}</p>
                        <router-link :to="{name: 'Contact'}"><button>{{$t("contactText1")}}</button></router-link>
                    </div>
                    <img class="reveal-4" src="../assets/images/1.jpg" alt="LesSial mission: service TI personnalisé, sur mesure">
                </div>
            </div>
        </div>
        <div class="engagement" id="engagement">
            <div class="card reveal-5">
                <p>
                    <span>{{$t("secMissionText3")[0]}}</span>{{$t("secMissionText3").slice(1)}}
                </p>
            </div>
        </div>
        
        <Footer/>
    </div>
</template>

<script>
import Header from '../components/layouts/Header.vue';
import Footer from '../components/layouts/Footer.vue';
export default {
   name: "About",
   metaInfo: {
    title: `LesSial SERVICES INFORMATIQUES AL, Qui sommes nous`,
    htmlAttrs: {lang: 'fr'},
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: "LesSial SERVICES INFORMATIQUES AL, À l'air de la modernisation et même du télétravail qui s'impose de plus en plus, il est plus que jamais utile et nécessaire pour tout professionnel de bien s'outiller et de trouver des solutions technologiques novatrices afin de faciliter son travail et celui de ses employés dans l'optique de mieux répondre aux besoins de sa clientèle." },
      { name: 'robots', content: "LesSial, powerapps, powerautomate, powerplatform, office365, sharepoint, cybersecurite, saleforce, développement, application, informatique, Service informatique" },
      { name: 'googlebot', content: "LesSial, powerapps, powerautomate, powerplatform, office365, sharepoint, cybersecurite, saleforce, développement, application, informatique, Service informatique" },
      { name: 'google', content: 'nositelinkssearchbox' },
      { name: 'googlebot', content: 'notranslate' },
    ]
  },
   components: {Header, Footer},

   data(){
       return {
         
       }
   },

    props: {
        isReload: Boolean,
    },

    methods: {
        
        scriptApp(){
            let body = document.querySelector(".firstPage");
            let burger = document.querySelector(".navigation-burger");
            let navLinks = document.querySelector(".navigation-links");
            let nav = document.querySelector(".navigation");
            burger.addEventListener("click", () => {
            burger.classList.toggle("active");
            navLinks.classList.toggle("active");
            body.classList.toggle("active");
            });

            var lastScrollTop = 0;

            window.addEventListener(
            "scroll",
            function () {
                if (!(burger.classList.contains("active"))) {
                var st = window.pageYOffset || document.documentElement.scrollTop;
                if (st > lastScrollTop) {
                    nav.classList.add("fixed");
                }
                if (st < lastScrollTop) {
                    nav.classList.remove("fixed");
                }
                if (window.scrollY == 0) {
                    nav.classList.remove("fixed");
                }
                lastScrollTop = st;
                }
            },
            false
            );
        },

        scriptAnim(){
            const ratio = .1
            var options = {
            root: null,
            rootMargin: '0px',
            threshold: ratio
            }

            const handleIntersect = function (entries, observer) {
                entries.forEach(function (entry) { 
                    if (entry.intersectionRatio > ratio) {
                    entry.target.classList.add('reveal-visible')
                    observer.unobserve(entry.target) 
                    } 
                })
            }

            var observer = new IntersectionObserver(handleIntersect, options);
            document.querySelectorAll('[class*="reveal-"]').forEach(element => {
                observer.observe(element)
            });
        }
    },

    mounted: function(){
        this.scriptApp();
        this.scriptAnim();
    }

}
</script>

<style scoped>

</style>
