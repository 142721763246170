<template>
    <div class="firstPage">
        <Header currentTab="price"/> 
        
        <div class="price" id="price">
            <h2 class="reveal-1">{{$t("secPrice")}}</h2>
            <div class="cards">
                <div class="price-card reveal-2">
                    <ion-icon name="time"></ion-icon>
                    <h3>{{$t("secPriceText1")}}</h3>
                </div>
                <div class="price-card reveal-3">
                    <ion-icon name="send"></ion-icon>
                    <h3>{{$t("secPriceText2")}}</h3>
                </div>
                <div class="price-card reveal-4">
                    <ion-icon name="book"></ion-icon>
                    <h3>{{$t("secPriceText3")}}</h3>
                </div>
                <div class="price-card reveal-5">
                    <ion-icon name="code"></ion-icon>
                    <h3>{{$t("secPriceText4")}}</h3>
                </div>
                <div class="price-card reveal-6">
                    <ion-icon name="cash"></ion-icon>
                    <h3>{{$t("secPriceText5")}}</h3>
                </div>
            </div>
        </div>        

        <div class="contact" id="contact">
            <h2 class="reveal-1">Nous contacter</h2>
            <div class="cards reveal-2">
               <ContactUs/>
                <!-- TODO Add spinner when sending message -->
            </div>
        </div>
        
        <Footer/>
    </div>
</template>

<script>
import Header from '../components/layouts/Header.vue';
import Footer from '../components/layouts/Footer.vue';
import ContactUs from '../components/forms/ContactUs.vue';

export default {
   name: "Price",
    metaInfo: {
        title: `LesSial SERVICES INFORMATIQUES AL, Nos prix`,
        htmlAttrs: {lang: 'fr'},
        meta: [
        { name: 'description', content: "LesSial SERVICES INFORMATIQUES AL, Jusqu'à 3h de consultation gratuite, Soumission et Estimation gratuite, Support applicatif garantie et offert, Code source offert, Prix et paiement flexible" },
        { name: 'robots', content: "LesSial, powerapps, powerautomate, powerplatform, office365, sharepoint, cybersecurite, saleforce, développement, application, informatique, Service informatique" },
        { name: 'googlebot', content: "LesSial, powerapps, powerautomate, powerplatform, office365, sharepoint, cybersecurite, saleforce, développement, application, informatique, Service informatique" },
        ]
    },
   components: {Header, Footer, ContactUs},

   data(){
       return {
         
       }
   },

    props: {
        isReload: Boolean,
    },

    methods: {
        
        scriptApp(){
            let body = document.querySelector(".firstPage");
            let burger = document.querySelector(".navigation-burger");
            let navLinks = document.querySelector(".navigation-links");
            let nav = document.querySelector(".navigation");
            burger.addEventListener("click", () => {
            burger.classList.toggle("active");
            navLinks.classList.toggle("active");
            body.classList.toggle("active");
            });

            var lastScrollTop = 0;

            window.addEventListener(
            "scroll",
            function () {
                if (!(burger.classList.contains("active"))) {
                var st = window.pageYOffset || document.documentElement.scrollTop;
                if (st > lastScrollTop) {
                    nav.classList.add("fixed");
                }
                if (st < lastScrollTop) {
                    nav.classList.remove("fixed");
                }
                if (window.scrollY == 0) {
                    nav.classList.remove("fixed");
                }
                lastScrollTop = st;
                }
            },
            false
            );
        },

        scriptAnim(){
            const ratio = .1
            var options = {
            root: null,
            rootMargin: '0px',
            threshold: ratio
            }

            const handleIntersect = function (entries, observer) {
                entries.forEach(function (entry) { 
                    if (entry.intersectionRatio > ratio) {
                    entry.target.classList.add('reveal-visible')
                    observer.unobserve(entry.target) 
                    } 
                })
            }

            var observer = new IntersectionObserver(handleIntersect, options);
            document.querySelectorAll('[class*="reveal-"]').forEach(element => {
                observer.observe(element)
            });
        }
    },

    mounted: function(){
        this.scriptApp();
        this.scriptAnim();
    }

}
</script>

<style scoped>

</style>
