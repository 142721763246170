<template>
    <div class="firstPage">
        <Header currentTab="service"/>        
        <div class="services reveal" id="services">
           <h2 class="reveal-1">{{$t("secService")}}</h2>
            <!-- <p class="reveal-2">
                {{$t("secServiceText1")}}
            </p> -->
            <div class="cards reveal">
                <div class="services-card reveal-3">
                    <img src="../assets/images/9.jpg" alt="LesSial Services: Gestion de la Plateforme Office 365">
                    <h3>{{$t("secServiceOffice")}}</h3>
                    <p> {{$t("secServiceOfficeText")}}</p>
                </div>
                <div class="services-card reveal-4">
                    <img src="../assets/images/appService.jpeg" alt="LesSial Services: Développement de solutions applicative web et mobile avec la Power Platform">
                    <h3>{{$t("secServiceDevApp")}}</h3>
                    <p>{{$t("secServiceDevAppText")}}</p>
                </div>
                <div class="services-card reveal-5">
                    <img src="../assets/images/cyberSecu.jpeg" alt="LesSial Services: Cyber sécurité">
                    <h3>{{$t("secServiceCyberSecu")}}</h3>
                    <p>{{$t("secServiceCyberSecuText")}}</p>
                </div>
                <div class="services-card reveal-6">
                    <img src="../assets/images/10.jpg" alt="LesSial Services: Support Technique">
                    <h3>{{$t("secServiceSupport")}}</h3>
                    <p>{{$t("secServiceSupportText")}}</p>
                </div>
            </div>
        </div>
        
        <Footer/>
    </div>
</template>

<script>
import Header from '../components/layouts/Header.vue';
import Footer from '../components/layouts/Footer.vue';
export default {
   name: "Service",
   metaInfo: {
    title: `LesSial SERVICES INFORMATIQUES AL, Nos services`,
    htmlAttrs: {lang: 'fr'},
    meta: [
      { name: 'description', content: "LesSial SERVICES INFORMATIQUES AL, LesSial sont specialisés dans les services suivants: Gestion de la Plateforme Office 365, Développement de solutions applicative web et mobile avec la Power Platform, Cyber sécurité, Support Technique" },
      { name: 'robots', content: "LesSial, powerapps, powerautomate, powerplatform, office365, sharepoint, cybersecurite, saleforce, développement, application, informatique, Service informatique" },
      { name: 'googlebot', content: "LesSial, powerapps, powerautomate, powerplatform, office365, sharepoint, cybersecurite, saleforce, développement, application, informatique, Service informatique" },
      { name: 'google', content: 'nositelinkssearchbox' },
      { name: 'googlebot', content: 'notranslate' },
    ]
   },
   components: {Header, Footer},

   data(){
       return {
         
       }
   },

    props: {
        isReload: Boolean,
    },

    methods: {
        
        scriptApp(){
            let body = document.querySelector(".firstPage");
            let burger = document.querySelector(".navigation-burger");
            let navLinks = document.querySelector(".navigation-links");
            let nav = document.querySelector(".navigation");
            burger.addEventListener("click", () => {
            burger.classList.toggle("active");
            navLinks.classList.toggle("active");
            body.classList.toggle("active");
            });

            var lastScrollTop = 0;

            window.addEventListener(
            "scroll",
            function () {
                if (!(burger.classList.contains("active"))) {
                var st = window.pageYOffset || document.documentElement.scrollTop;
                if (st > lastScrollTop) {
                    nav.classList.add("fixed");
                }
                if (st < lastScrollTop) {
                    nav.classList.remove("fixed");
                }
                if (window.scrollY == 0) {
                    nav.classList.remove("fixed");
                }
                lastScrollTop = st;
                }
            },
            false
            );
        },

        scriptAnim(){
            const ratio = .1
            var options = {
            root: null,
            rootMargin: '0px',
            threshold: ratio
            }

            const handleIntersect = function (entries, observer) {
                entries.forEach(function (entry) { 
                    if (entry.intersectionRatio > ratio) {
                    entry.target.classList.add('reveal-visible')
                    observer.unobserve(entry.target) 
                    } 
                })
            }

            var observer = new IntersectionObserver(handleIntersect, options);
            document.querySelectorAll('[class*="reveal-"]').forEach(element => {
                observer.observe(element)
            });
        }
    },

    mounted: function(){
        this.scriptApp();
        this.scriptAnim();
    }

}
</script>

<style scoped>

</style>
