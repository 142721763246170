<template>
  <div>
    <router-view />
  </div>
</template>

<script>
// import * as email from './utils/nodemailer';
// import * as apis from './api/callout'
const $ = require("jquery");
window.$ = $;

export default {
  metaInfo: {
    title: `LesSial SERVICES INFORMATIQUES AL`,
    htmlAttrs: {lang: 'fr'},
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: "LesSial SERVICES INFORMATIQUES AL, Nous sommes une équipe d'experts TI, spécialisée en intelligence d'affaire, en développement d'applications et en cybersécurité et ayant une grande expérience dans le domaine qui avons décidé de nous occuper personnellement de vous car pour nous, tout professionnel a besoin d'une bon accompagnement TI" },
      { name: 'robots', content: "LesSial, powerapps, powerautomate, powerplatform, office365, sharepoint, cybersecurite, saleforce, développement, application, informatique, Service informatique" },
      { name: 'googlebot', content: "LesSial, powerapps, powerautomate, powerplatform, office365, sharepoint, cybersecurite, saleforce, développement, application, informatique, Service informatique" },
      { name: 'google', content: 'nositelinkssearchbox' },
      { name: 'googlebot', content: 'notranslate' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  methods: {
    
  },

  mounted: async function(){
      //  console.log('>>>>>>>>>>>>>', email);
      // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> send mail: ', await apis.sendmail());
   }
}
</script>

<style>
@import "./assets/css/style.css";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

</style>
