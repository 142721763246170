<template>
    <div class="firstPage">
        <Header currentTab="portfolio"/>        
        
        <div class="portfolio reveal" id="portfolio">
            <h2 class="reveal-1">{{$t("achievement")}}</h2>
            <div class="cards">
                <div class="portfolio-card">
                    <div class="container" @mouseover="stopSliding = true" @mouseleave="stopSliding = false">
                        <!-- Full-width images with number text -->
                        <!-- <div @mouseover="stopSliding = true" @mouseleave="stopSliding = false"> -->
                            <div class="mySlides">
                                <img src="../assets/images/portfolio1.png" alt="LesSial nos ouvrages: Outil de facturation" style="width:100%; height: 400px;">
                            </div>
                            <div class="mySlides">
                                <img src="../assets/images/portfolio2.png" alt="LesSial nos ouvrages: Plateforme d'envoie de SMS" style="width:100%; height: 400px;">
                            </div>
                        <!-- </div> -->

                        <!-- Next and previous buttons -->
                        <a class="prev" @click="plusSlides(-1)">&#10094;</a>
                        <a class="next" @click="plusSlides(1)">&#10095;</a>

                        <!-- Image text -->
                        <div class="caption-container">
                            <p id="caption"></p>
                        </div>

                        <!-- Thumbnail images -->
                        <div class="row">
                            <div class="column">
                                <img class="demo cursor" src="../assets/images/portfolio1.png" style="width:100%; height: 100px;" @click="currentSlide(1)"
                                    :alt="$t('portFolio1')">
                            </div>
                            <div class="column">
                                <img class="demo cursor" src="../assets/images/portfolio2.png" style="width:100%; height: 100px;"
                                    @click="currentSlide(2)" :alt="$t('portFolio2')">
                            </div>
                        </div>
                    </div>
                </div>
                <h2>{{$t("technology")}}</h2>
                <div class="portfolio-techno">
                    <div class="card-techno reveal-1">{{$t("technologyText1")}}</div>
                    <div class="card-techno reveal-2">{{$t("technologyText2")}}</div>
                    <div class="card-techno reveal-3">{{$t("technologyText3")}}</div>
                    <div class="card-techno reveal-4">{{$t("technologyText4")}}</div>
                    <div class="card-techno reveal-5">{{$t("technologyText5")}}</div>
                    <div class="card-techno reveal-6">{{$t("technologyText6")}}</div>
                    <div class="card-techno reveal-7">{{$t("technologyText7")}}</div>
                    <div class="card-techno reveal-8">{{$t("technologyText8")}}</div>
                    <div class="card-techno reveal-9">{{$t("technologyText9")}}</div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from '../components/layouts/Header.vue';
import Footer from '../components/layouts/Footer.vue';

const $ = require("jquery");
window.$ = $;

export default {
   name: "PortFolio",
    metaInfo: {
    title: `LesSial SERVICES INFORMATIQUES AL, Nos projects realisés`,
    htmlAttrs: {lang: 'fr'},
    meta: [
      { name: 'description', content: "LesSial SERVICES INFORMATIQUES AL, Nos ouvrages: Outil de facturation, Plateforme d'envoie de SMS" },
      { name: 'robots', content: "LesSial, powerapps, powerautomate, powerplatform, office365, sharepoint, cybersecurite, saleforce, développement, application, informatique, Service informatique" },
      { name: 'googlebot', content: "LesSial, powerapps, powerautomate, powerplatform, office365, sharepoint, cybersecurite, saleforce, développement, application, informatique, Service informatique" },
    ]
   },
   components: {Header, Footer},

   data(){
       return {
            slideIndex: 1,
            stopSliding: false,
            refreshCarouselId: 0,
       }
   },

    props: {
        isReload: Boolean,
    },

    methods: {        
        scriptApp(){
            let body = document.querySelector(".firstPage");
            let burger = document.querySelector(".navigation-burger");
            let navLinks = document.querySelector(".navigation-links");
            let nav = document.querySelector(".navigation");
            burger.addEventListener("click", () => {
            burger.classList.toggle("active");
            navLinks.classList.toggle("active");
            body.classList.toggle("active");
            });

            var lastScrollTop = 0;

            window.addEventListener(
            "scroll",
            function () {
                if (!(burger.classList.contains("active"))) {
                var st = window.pageYOffset || document.documentElement.scrollTop;
                if (st > lastScrollTop) {
                    nav.classList.add("fixed");
                }
                if (st < lastScrollTop) {
                    nav.classList.remove("fixed");
                }
                if (window.scrollY == 0) {
                    nav.classList.remove("fixed");
                }
                lastScrollTop = st;
                }
            },
            false
            );
        },

        scriptAnim(){
            const ratio = .1
            var options = {
            root: null,
            rootMargin: '0px',
            threshold: ratio
            }

            const handleIntersect = function (entries, observer) {
                entries.forEach(function (entry) { 
                    if (entry.intersectionRatio > ratio) {
                    entry.target.classList.add('reveal-visible')
                    observer.unobserve(entry.target) 
                    } 
                })
            }

            var observer = new IntersectionObserver(handleIntersect, options);
            document.querySelectorAll('[class*="reveal-"]').forEach(element => {
                observer.observe(element)
            });
        },

        // Next/previous controls
        plusSlides(n) {
            this.showSlides(this.slideIndex += n);
        },

        // Thumbnail image controls
        currentSlide(n) {
            this.showSlides(this.slideIndex = n);
        },

        showSlides(n) {
            let i;
            let slides = document.getElementsByClassName("mySlides");
            let dots = document.getElementsByClassName("demo");
            let captionText = document.getElementById("caption");
            if (n > slides.length) { this.slideIndex = 1 }
            if (n < 1) { this.slideIndex = slides.length }
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(" active", "");
            }
            slides[this.slideIndex - 1].style.display = "block";
            slides[this.slideIndex - 1].style.transition = "all .7s ease-in-out;";
            dots[this.slideIndex - 1].className += " active";
            captionText.innerHTML = dots[this.slideIndex - 1].alt;
        },
        sliding(){
            this.refreshCarouselId = setInterval(() => {
                if(!this.stopSliding){
                    this.currentSlide(this.slideIndex == 1 ? 2 : 1);
                }
            }, 3e3);
        }
    },

    mounted: function(){
        this.scriptApp();
        this.scriptAnim();        
        this.showSlides(this.slideIndex);
        this.sliding();
    },

    beforeDestroy(){
        clearInterval(this.refreshCarouselId);
    }

}
</script>

<style scoped>

</style>
