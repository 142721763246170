<template>
    <div>        
        <nav class="navigation" id="navigation">
            <div class="navigation-logo">
                <router-link to = "/"><img src="../../assets/images/logo-nobg.png" alt="LesSial Services Informatique AL"></router-link>
            </div>
            <ul class="navigation-links">
                <li id="home" class="active"><router-link :to="{name:'Home', props:{isReload: true}}">{{ $t("home") }}</router-link></li>
                <li id="about"><router-link :to="{name:'About', props:{isReload: true}}">{{ $t("about") }}</router-link></li>
                <li id="service"><router-link :to="{name:'Service', props:{isReload: true}}">{{ $t("service") }}</router-link></li>
                <li id="portfolio"><router-link :to="{name:'PortFolio', props:{isReload: true}}">{{ $t("portfolio") }}</router-link></li>
                <li id="price"><router-link :to="{name:'Price', props:{isReload: true}}">{{ $t("price") }}</router-link></li>
                <li id="career"><router-link :to="{name:'Career', props:{isReload: true}}">{{ $t("career") }}</router-link></li>
                <li id="contact"><router-link :to="{name:'Contact', props:{isReload: true}}">{{ $t("contact") }}</router-link></li>
                <div class="dropdown">
                    <button class="dropbtn">
                        <ion-icon name="globe"></ion-icon>
                    </button>
                    <div class="dropdown-content">
                        <button id='fr' class="active" @click="changeLang('fr')">🇫🇷 Français</button>
                        <button id='en'  @click="changeLang('en')">🇬🇧 English</button>
                    </div>
                </div>
            </ul>
            <div class="navigation-burger">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </nav>
    </div>
</template>

<script>
import router from "../../router";

const $ = require("jquery");
window.$ = $;
export default {
    name: "Header",
    components: {},

    data(){
        return {}
    },
    
    props: {
        currentTab: String,
    },

    methods: {
        goAbout(){
            router.push({ name: "About" }).catch(() => {});
        },

        changeLang(lang){            
            sessionStorage.setItem('lesSial-lang', lang);
            this.$i18n.locale = lang;
            $('button.active').removeClass('active');
            $(`#${lang}`).addClass('active');
        }
    },

    mounted: function(){
        $('li').removeClass('active');
        $(`#${this.currentTab || 'home'}`).addClass('active');
        this.changeLang(sessionStorage.getItem('lesSial-lang'));
    },

    watch: {
    }

}
</script>

<style scoped>

</style>