import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/scss/style.scss'
import VueI18n from 'vue-i18n'
import * as langsText from './utils/multiLangText'
import Toasted from 'vue-toasted';
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
Vue.use(Toasted)
Vue.use(VueI18n)

sessionStorage.setItem('lesSial-lang', 'fr');
const messages = langsText.messages;
const i18n = new VueI18n({
    locale: sessionStorage.getItem('lesSial-lang'),
    messages
});

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
    i18n,
}).$mount('#app')