<template>
    <div>
        <footer class="footer" id="footer">
            <div class="cards">
                <li>
                    <ion-icon name="call"></ion-icon><span>{{$t('tel')}}:</span><a href="tel:+ 1 438 401 6973">+ 1 438 401 6973</a>
                </li>
                <li>
                    <ion-icon name="mail"></ion-icon><span>Email:</span><a href="mailto:info@lessial.onmicrosoft.com">info@lessial.ca</a>
                </li>
                <li>
                    <ion-icon name="pin"></ion-icon><span>{{$t('address')}} : 355 avenue 74, Terrebonne (QC), Canada, J6W 4R8</span>
                </li>
            </div>
        </footer>
        <a href="#header" class="arrow" v-if=" this.currentTab == 'home'">
            <ion-icon name="arrow-round-up"></ion-icon>
        </a>
        <h3 class="copy">&copy; 2022 Les SIAL. {{$t('privacyPolicy')}}</h3>
    </div>
</template>

<script>
export default {
    name: "Footer",
    components: {},
    props: {
        currentTab: String,
    }, 
    data(){
        return {
            
        }
    },

    methods: {
    },

    mounted: function(){
    }

}
</script>

<style scoped>

</style>