<template>
    <div class="firstPage">
        <Header/>

        <header class="header reveal" id="header">
            <div class="header-text">
                <h2 class="reveal-1"> {{ $t("welcome") }} </h2>
                    <h1 class="reveal-2"><span v-if="$i18n.locale=='en'">AL </span><span>{{$t("lesSial")[0]}}</span>{{$t("lesSial").split(' ')[0].slice(1)}} <span>{{$t("lesSial").split(' ')[1][0]}}</span>{{$t("lesSial").split(' ')[1].slice(1)}} <span v-if="$i18n.locale=='fr'">AL</span></h1>
                
                <a href="#mission" class="reveal-3"><button>{{$t("discover")}}</button></a>
            </div>
        </header>
        
        <div class="about">
            <div class="cards reveal">
                <div class="about-img reveal-1">
                    <img src="../assets/images/logo-nobg.png" alt="LesSial Services Informatiques AL">
                </div>
                <div class="about-infos">
                    <h1 class="reveal-1">{{$t("homeText1")}}</h1>
                    <div class="line reveal-2"></div>
                    <div class="text reveal-3">
                        <!-- <p>{{$t("homeText2")}}</p>                        
                        <p>{{$t("homeText3")}}</p>                        
                        <p>{{$t("homeText4")}}</p>                        
                        <p>{{$t("homeText5")}}</p>                        
                        <p>{{$t("homeText6")}}</p>                        
                        <p>{{$t("homeText7")}}</p>                        
                        <p>{{$t("homeText8")}}</p>                        
                        <p>{{$t("homeText9")}}</p>                         -->
                        <!-- <p>{{$t("homeText11")}}</p>                         -->
                        <p>{{$t("secServiceText1")}}</p>                        
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="ls_carousel_body">
            <div class='ls_wrapper'>
                <div class='ls_carousel'>
                    <div class='ls_carousel__item'>
                        <div class='ls_carousel__item-head'>1</div>
                        <div class='ls_carousel__item-body'>
                            <p>{{$t("homeText1")}}</p>
                        </div>
                    </div>
                    <div class='ls_carousel__item'>
                        <div class='ls_carousel__item-head'>2</div>
                        <div class='ls_carousel__item-body'>
                            <p>{{$t("homeText2")}}</p>
                        </div>
                    </div>
                    <div class='ls_carousel__item'>
                        <div class='ls_carousel__item-head'>3</div>
                        <div class='ls_carousel__item-body'>
                            <p>{{$t("homeText3")}}</p>
                        </div>
                    </div>
                    <div class='ls_carousel__item'>
                        <div class='ls_carousel__item-head'>4</div>
                        <div class='ls_carousel__item-body'>
                            <p>{{$t("homeText4")}}</p>
                        </div>
                    </div>
                    <div class='ls_carousel__item'>
                        <div class='ls_carousel__item-head'>5</div>
                        <div class='ls_carousel__item-body'>
                            <p>{{$t("homeText5")}}</p>
                        </div>
                    </div>
                    <div class='ls_carousel__item'>
                        <div class='ls_carousel__item-head'>6</div>
                        <div class='ls_carousel__item-body'>
                            <p>{{$t("homeText6")}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>   -->
        
        
        <div class="ls-carousel engagement reveal"  id="engagement">
            <ul class="slides card  reveal-1"  @mouseover="stopSliding = true" @mouseleave="stopSliding = false">                 
                <div class="slide-number">{{ active }}</div>
                <template v-for="(s, i) in slides">   
                    <input :key="i" type="radio" name="radio-buttons" :id="'img-'+s.id" :checked="s.id==1"/>
                    <li :key="(i+1)*10" class="slide-container">
                        <div class="slide-image">
                            <p><span>{{ $t(s.text)[0] }}</span>{{ $t(s.text).slice(1) }}</p>
                        </div>
                    </li>
                </template>
                <div class="ls-carousel-dots">
                    <label v-for="(s, i) in slides" :key="(i+1)*100" :for="'img-'+s.id" class="ls-carousel-dot" :id="'img-dot-'+s.id"  @click="updateActive(s.id)"></label>
                </div>
            </ul>
        </div>

        <!-- <div class="engagement" id="engagement">
            <div class="card reveal-4">
                <p><span>{{$t("homeText10")[0]}}</span>{{$t("homeText10").slice(1)}}</p>
            </div>
        </div> -->

        <div class="mission reveal" id="mission">
            <h2 class="reveal-1">{{$t("secMission")}}</h2>
            <div class="cards">
                <div class="mission-card">
                    <img src="../assets/images/7.jpg" alt="LesSial mission: solution technologique" class="reveal-2">
                    <div class="mission-card-text reveal-3">
                        <p>{{$t("secMissionText1")}}</p>
                        <a href="#contact-us"><button>{{$t("contactText1")}}</button></a>
                    </div>
                </div>
                <div class="mission-card reveal">
                    <div class="mission-card-text reveal-4">
                        <p>{{$t("secMissionText2")}}</p>
                        <a href="#contact-us"><button>{{$t("contactText1")}}</button></a>
                    </div>
                    <img src="../assets/images/1.jpg" alt="LesSial mission: service TI personnalisé, sur mesure" class="reveal-5">
                </div> 
            </div>
        </div>

        <div class="services reveal" id="services">
            <h2 class="reveal-1">{{$t("secService")}}</h2>
            <!-- <p class="reveal-2">
                {{$t("secServiceText1")}}
            </p> -->
            <div class="cards">
                <div class="services-card reveal-3">
                    <img src="../assets/images/9.jpg" alt="LesSial Services: Gestion de la Plateforme Office 365">
                    <h3>{{$t("secServiceOffice")}}</h3>
                    <p> {{$t("secServiceOfficeText")}}</p>
                </div>
                <div class="services-card reveal-4">
                    <img src="../assets/images/appService.jpeg" alt="LesSial Services: Développement de solutions applicative web et mobile avec la Power Platform">
                    <h3>{{$t("secServiceDevApp")}}</h3>
                    <p>{{$t("secServiceDevAppText")}}</p>
                </div>
                <div class="services-card reveal-5">
                    <img src="../assets/images/cyberSecu.jpeg" alt="LesSial Services: Cyber sécurité">
                    <h3>{{$t("secServiceCyberSecu")}}</h3>
                    <p>{{$t("secServiceCyberSecuText")}}</p>
                </div>
                <div class="services-card reveal-6">
                    <img src="../assets/images/10.jpg" alt="LesSial Services: Support Technique">
                    <h3>{{$t("secServiceSupport")}}</h3>
                    <p>{{$t("secServiceSupportText")}}</p>
                </div>
            </div>
        </div>

        <div class="contact reveal" id="contact-us">
            <h2 class="reveal-1">{{$t("secContactUs")}}</h2>
            <div class="cards reveal-2">
               <ContactUs/>
            </div>
        </div>

        <div class="price reveal" id="price">
            <h2 class="reveal-1">{{$t("secPrice")}}</h2>
            <div class="cards">
                <div class="price-card reveal-2">
                    <ion-icon name="time"></ion-icon>
                    <h3>{{$t("secPriceText1")}}</h3>
                </div>
                <div class="price-card reveal-3">
                    <ion-icon name="send"></ion-icon>
                    <h3>{{$t("secPriceText2")}}</h3>
                </div>
                <div class="price-card reveal-4">
                    <ion-icon name="book"></ion-icon>
                    <h3>{{$t("secPriceText3")}}</h3>
                </div>
                <div class="price-card reveal-5">
                    <ion-icon name="code"></ion-icon>
                    <h3>{{$t("secPriceText4")}}</h3>
                </div>
                <div class="price-card reveal-6">
                    <ion-icon name="cash"></ion-icon>
                    <h3>{{$t("secPriceText5")}}</h3>
                </div>
            </div>
            <router-link :to="{name: 'Career'}" class="reveal-7"><button>{{$t("career")}}</button></router-link>
        </div>
        
        <Footer currentTab="home"/>
    </div>
</template>

<script>
import Header from '../components/layouts/Header.vue';
import Footer from '../components/layouts/Footer.vue';
import ContactUs from '../components/forms/ContactUs.vue';

const $ = require("jquery");
window.$ = $;

export default {
    name: "Home",
    components: {Header, Footer, ContactUs},

    data(){
        return {
            componentKey: 0,
            slides: [ { id: 1, text: "homeText1" }, { id: 2, text: "homeText2" }, { id: 3, text: "homeText3" }, { id: 4, text: "homeText4" },
                      { id: 5, text: "homeText5" }, { id: 6, text: "homeText6" }, { id: 7, text: "homeText7" }, { id: 8, text: "homeText8" },
                      { id: 9, text: "homeText10" }
                    ],
            active: 0,
            stopSliding: false,
            refreshCarouselId: 0,
        }
    },
    
    props: {
        
    },
    methods: {

        scriptApp(){
            let body = document.querySelector(".firstPage");
            let burger = document.querySelector(".navigation-burger");
            let navLinks = document.querySelector(".navigation-links");
            let nav = document.querySelector(".navigation");
            burger.addEventListener("click", () => {
            burger.classList.toggle("active");
            navLinks.classList.toggle("active");
            body.classList.toggle("active");
            });

            window.addEventListener('scroll', ()=>{
                let arrow = document.querySelector('.arrow');
                if(arrow) arrow.classList.toggle('arrow-hidde', window.scrollY > (screen.height) / 2);
            })
            var lastScrollTop = 0;

            window.addEventListener(
            "scroll",
            function () {
                if (!(burger.classList.contains("active"))) {
                var st = window.pageYOffset || document.documentElement.scrollTop;
                if (st > lastScrollTop) {
                    nav.classList.add("fixed");
                }
                if (st < lastScrollTop) {
                    nav.classList.remove("fixed");
                }
                if (window.scrollY == 0) {
                    nav.classList.remove("fixed");
                }
                lastScrollTop = st;
                }
            },
            false
            );
        },

        scriptAnim(){
            const ratio = .1
            var options = {
            root: null,
            rootMargin: '0px',
            threshold: ratio
            }

            const handleIntersect = function (entries, observer) {
                entries.forEach(function (entry) { 
                    if (entry.intersectionRatio > ratio) {
                    entry?.target?.classList?.add('reveal-visible')
                    observer.unobserve(entry.target) 
                    } 
                })
            }

            var observer = new IntersectionObserver(handleIntersect, options);
            document.querySelectorAll('[class*="reveal-"]').forEach(element => {
                observer.observe(element)
            });
        },
        sliding(){
            this.refreshCarouselId = setInterval(() => {
                if(!this.stopSliding){
                    this.active = this.active >= this.slides.length ? 1 : ++this.active;
                    $(`#img-dot-${this.active}`).click();
                }
            }, 4e3);
        },
        updateActive(index){
            if(index) this.active = index;
        }
    },

    mounted: async function(){        
        this.active = 1;
        // clearInterval(this.refreshCarouselId);
        this.scriptApp();
        this.scriptAnim();
        this.sliding();
    },

    beforeDestroy(){
        clearInterval(this.refreshCarouselId);
    }
}
</script>

<style scoped>

</style>
