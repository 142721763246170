<template>
    <div class="firstPage">
        <Header currentTab="career"/>        
        <div class="career" id="career">
            <h2 class="reveal-1">{{$t('career')}}</h2>
            <div class="cards">
                <div class="career-indispo">
                    <h1>{{$t('noPosition')}}</h1>
                </div>
                <!-- <div class="career-card reveal-2">
                    <img src="../assets/images/undraw_Interview_re_e5jn.png" alt="LesSial carrières: agent marketing">
                    <h3>{{$t('careerText1')}}</h3>
                    <a href="#formCV"><button>{{$t('careerApply')}}</button></a>
                </div>
                <div class="career-card reveal-3">
                    <img src="../assets/images/undraw_job_offers_kw5d.png" alt="LesSial carrières: Web disigner">
                    <h3>{{$t('careerText2')}}</h3>
                    <a href="#formCV"><button>{{$t('careerApply')}}</button></a>
                </div>
                <div class="career-card reveal-4">
                    <img src="../assets/images/undraw_Updated_resume_re_q1or.png" alt="LesSial carrières: Agent réseaux">
                    <h3>{{$t('careerText3')}}</h3>
                    <a href="#formCV"><button>{{$t('careerApply')}}</button></a>
                </div> -->
            </div>
        </div>         

        <!-- <div class="career-contact" id="formCV">
            <form class="contact-card" ref="form" @submit.prevent="sendEmailCV">
                <h3>{{$t("secContactUsFormHeader")}}</h3>
                <div class="inputBox">
                    <input type="text" :placeholder="$t('secContactUsFormName')" required>
                </div>
                <div class="inputBox">
                    <input type="text" :placeholder="$t('secContactUsFormLastName')" required>
                </div>
                <div class="inputBox">
                    <input type="email" :placeholder="$t('secContactUsFormEmail')" required>
                </div>
                <div class="inputBox">
                    <input type="text" :placeholder="$t('secContactUsFormPost')" required>
                </div>
                <div class="inputBox">
                    <input type="file" :placeholder="$t('secContactUsFormCV')" required>
                </div>
                <div class="inputBox">
                    <input type="submit" :value="$t('secContactUsFormSubmit')" :disabled="isSending">
                </div>
            </form>
            TODO Add spinner when sending message
        </div> -->
        
        <Footer/>
    </div>
</template>

<script>
import Header from '../components/layouts/Header.vue';
import Footer from '../components/layouts/Footer.vue';
export default {
   name: "Career",
   components: {Header, Footer},

   data(){
       return {
         
       }
   },

    props: {
        isReload: Boolean,
    },

    methods: {
        
        scriptApp(){
            let body = document.querySelector(".firstPage");
            let burger = document.querySelector(".navigation-burger");
            let navLinks = document.querySelector(".navigation-links");
            let nav = document.querySelector(".navigation");
            burger.addEventListener("click", () => {
            burger.classList.toggle("active");
            navLinks.classList.toggle("active");
            body.classList.toggle("active");
            });

            var lastScrollTop = 0;

            window.addEventListener(
            "scroll",
            function () {
                if (!(burger.classList.contains("active"))) {
                var st = window.pageYOffset || document.documentElement.scrollTop;
                if (st > lastScrollTop) {
                    nav.classList.add("fixed");
                }
                if (st < lastScrollTop) {
                    nav.classList.remove("fixed");
                }
                if (window.scrollY == 0) {
                    nav.classList.remove("fixed");
                }
                lastScrollTop = st;
                }
            },
            false
            );
        },

        scriptAnim(){
            const ratio = .1
            var options = {
            root: null,
            rootMargin: '0px',
            threshold: ratio
            }

            const handleIntersect = function (entries, observer) {
                entries.forEach(function (entry) { 
                    if (entry.intersectionRatio > ratio) {
                    entry.target.classList.add('reveal-visible')
                    observer.unobserve(entry.target) 
                    } 
                })
            }

            var observer = new IntersectionObserver(handleIntersect, options);
            document.querySelectorAll('[class*="reveal-"]').forEach(element => {
                observer.observe(element)
            });
        }
    },

    mounted: function(){
        this.scriptApp();
        this.scriptAnim();
    }

}
</script>

<style scoped>
.career-indispo {
    font-family: "Birthstone", serif;
    padding: 0.5rem;
    text-align: center;
    height: 40vh;
}
</style>
